<template>
  <nav class="sticky top-0 border-black-10 bg-primary z-20 border-b">
    <div class="w-11/12 flex justify-between items-center h-14 px-4 mx-auto md:w-full md:gap-4">
      <div
        class="font-wide text-purple dark-bg:text-pink text-base grow basis-0 transition-colors duration-700 lg:hidden"
      >
        <NuxtLink
          :to="localePath('/')"
          class="rounded-s dark-bg:hover:bg-pink/5 hover:bg-purple/5 px-4 py-3 transition-colors ms-[-1rem] duration-200"
        >
          {{ $t('brand.thg') }}
        </NuxtLink>
      </div>
      <div
        class="hidden font-wide text-purple dark-bg:text-pink text-base grow basis-0 transition-colors duration-700 lg:block sm:hidden"
      >
        <NuxtLink
          :to="localePath('/')"
          class="rounded-s dark-bg:hover:bg-pink/5 hover:bg-purple/5 px-4 py-3 transition-colors ms-[-1rem] duration-200"
        >
          {{ $t('brand.thg-short') }}
        </NuxtLink>
      </div>

      <!-- <AppSearch class="w-1/3 max-w-md min-w-[21rem] md:max-w-full md:grow sm:min-w-0" /> -->
      <Transition mode="out-in">
        <AppSearch
          class="sm:ms-auto"
          v-if="
            !route.name ||
            !route.name.toString().includes('index__') ||
            (route.name.toString().includes('index__') && y > 300)
          "
        />
        <NuxtLink
          v-else
          :to="localePath('/')"
          class="hidden font-wide text-orange dark-bg:text-pink text-base grow basis-0 transition-colors duration-700 sm:block"
        >
          {{ $t('brand.thg') }}
        </NuxtLink>
      </Transition>
      <div class="flex items-center gap-2 grow basis-0 justify-end lg:hidden">
        <NuxtLink
          :to="localePath({ name: 'how' })"
          class="text-sm whitespace-nowrap px-4 py-2 rounded-s dark-bg:text-white item transition-colors duration-200 dark-bg:hover:bg-white/5 hover:bg-black/5"
          >{{ $t('pages.how') }}</NuxtLink
        >
        <NuxtLink
          :to="localePath({ name: 'about' })"
          class="text-sm whitespace-nowrap px-4 py-2 rounded-s dark-bg:text-white item transition-colors duration-200 dark-bg:hover:bg-white/5 hover:bg-black/5"
          >{{ $t('pages.about') }}</NuxtLink
        >
        <ProfileNavAvatar
          class="w-8 ms-2 hover:scale-[.95] transition-transform"
        ></ProfileNavAvatar>
      </div>
      <div
        class="hidden lg:block grow basis-0 ms-auto float-right sm:basis-auto sm:grow-0 sm:shrink-0"
      >
        <img
          src="/img/burger.svg"
          alt="Hamburger Menu Icon"
          class="float-right cursor-pointer"
          @click="menuIsOpen = !menuIsOpen"
        />
      </div>
    </div>
  </nav>
  <Transition>
    <menu
      v-if="menuIsOpen"
      class="fixed top-14 left-0 bottom-0 right-0 bg-primary p-4 z-10 transition-colors duration-800 hidden lg:block"
    >
      <div class="w-4/6 mx-auto xl:w-11/12 md:w-full">
        <ProfileNavAvatar @click="menuIsOpen = !menuIsOpen" class="w-10 h-10"></ProfileNavAvatar>
      </div>

      <div class="flex min-h-full flex-col justify-center gap-4 m-auto w-9/12 pb-28 pt-4 sm:w-full">
        <NuxtLink
          :to="localePath({ name: 'index' })"
          @click="menuIsOpen = !menuIsOpen"
          class="text-2xl text-secondary transition-colors duration-700 no-underline"
        >
          {{ $t('pages.home') }}
        </NuxtLink>
        <NuxtLink
          :to="localePath({ name: 'how' })"
          @click="menuIsOpen = !menuIsOpen"
          class="text-2xl text-secondary transition-colors duration-700 no-underline"
          >{{ $t('pages.how') }}</NuxtLink
        >
        <NuxtLink
          :to="localePath({ name: 'about' })"
          @click="menuIsOpen = !menuIsOpen"
          class="text-2xl text-secondary transition-colors duration-700 no-underline"
        >
          {{ $t('pages.about') }}</NuxtLink
        >
      </div>
    </menu>
  </Transition>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/Auth';
import { storeToRefs } from 'pinia';

const { y } = useWindowScroll();
const route = useRoute();
// const sectionColors = useState('sectionColors', () => null);
// const isDarkBg = useState<boolean>('darkBg', () => false);

const localePath = useLocalePath();
const store = useAuthStore();
const { isLoggedIn } = storeToRefs(store);

let menuIsOpen = ref(false);
</script>

<style scoped>
.v-enter-active {
  transition:
    transform 0.6s cubic-bezier(0.4, 1.5, 0.35, 1),
    opacity 0.4s;
}

.v-leave-active {
  transition:
    transform 0.2s,
    opacity 0.2s;
}

.v-enter-from {
  transform: translateY(15%);
  opacity: 0;
}

.v-leave-to {
  transform: translateY(15%);
  opacity: 0;
}

.item.router-link-active {
  @apply underline underline-offset-4 decoration-dotted decoration-black/40;
}
</style>
