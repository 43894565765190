<template>
  <p
    class="ml-auto text-black-50 relative transition-colors sm:order-4 sm:ms-auto sm:grow sm:w-1/3 sm:text-right sm:static"
    ref="credits"
  >
    <span @click="creditsVisible = true" class="hover:text-purple cursor-pointer">
      {{ $t('footer.authors.created-by') }}
      <span class="h-[1.3em] overflow-hidden inline-block align-top">
        <span class="inline-flex flex-col slide">
          <span>{{ $t('footer.authors.armin.name') }}</span>
          <span>{{ $t('footer.authors.tobija.name') }}</span>
          <span>{{ $t('footer.authors.robin.name') }}</span>
          <span>{{ $t('footer.authors.simon.name') }}</span>
          <span>{{ $t('footer.authors.armin.name') }}</span>
        </span>
      </span>
    </span>

    <Transition>
      <div
        v-if="creditsVisible"
        class="flex flex-col p-4 min-w-64 bg-white border border-black-10 rounded-t-m text-black text-left fixed right-0 left-0 bottom-0 w-full shadow min-h-[50vh] max-h-[calc(100vh-5rem)] overflow-auto"
      >
        <button
          @click="creditsVisible = false"
          class="ml-auto p-3 bg-black-4 rounded-s hover:scale-[.95] transition-transform"
        >
          <img src="/img/close.svg" class="w-5 h-5" />
        </button>
        <div
          class="flex justify-center items-center divide-x divide-black-10 h-full grow mb-16 lg:flex-col lg:divide-x-0 lg:divide-y"
        >
          <NuxtLink
            class="p-8 hover:text-purple hover:px-12 transition-all duration-300 group lg:w-full lg:hover:px-8 sm:py-4"
            :to="$t('footer.authors.robin.url')"
            target="_blank"
          >
            <span class="text-sm block text-black-50">{{ $t('footer.authors.robin.role') }}</span>
            <span class="inline-block w-full text-lg"
              >{{ $t('footer.authors.robin.name-full') }}
              <img
                class="inline float-right h-4 ms-2 opacity-0 group-hover:opacity-100 transition-opacity"
                src="/img/external-link.svg"
                alt=""
            /></span>
          </NuxtLink>
          <NuxtLink
            class="p-8 hover:text-purple hover:px-12 transition-all duration-300 group lg:w-full lg:hover:px-8 sm:py-4"
            :to="$t('footer.authors.tobija.url')"
            target="_blank"
          >
            <span class="text-sm block text-black-50">{{ $t('footer.authors.tobija.role') }}</span>
            <span class="inline-block w-full text-lg"
              >{{ $t('footer.authors.tobija.name-full') }}
              <img
                class="inline float-right h-4 ms-2 opacity-0 group-hover:opacity-100 transition-opacity"
                src="/img/external-link.svg"
                alt=""
            /></span>
          </NuxtLink>
          <NuxtLink
            class="p-8 hover:text-purple hover:px-12 transition-all duration-300 group lg:w-full lg:hover:px-8 sm:py-4"
            :to="$t('footer.authors.armin.url')"
            target="_blank"
          >
            <span class="text-sm block text-black-50">{{ $t('footer.authors.armin.role') }}</span>
            <span class="inline-block w-full text-lg"
              >{{ $t('footer.authors.armin.name-full') }}
              <img
                class="inline float-right h-4 ms-2 opacity-0 group-hover:opacity-100 transition-opacity"
                src="/img/external-link.svg"
                alt=""
            /></span>
          </NuxtLink>
          <NuxtLink
            class="p-8 hover:text-purple hover:px-12 transition-all duration-300 group lg:w-full lg:hover:px-8 sm:py-4"
            :to="$t('footer.authors.simon.url')"
            target="_blank"
          >
            <span class="text-sm block text-black-50">{{ $t('footer.authors.simon.role') }}</span>
            <span class="inline-block w-full text-lg"
              >{{ $t('footer.authors.simon.name-full') }}
              <img
                class="inline float-right h-4 ms-2 opacity-0 group-hover:opacity-100 transition-opacity"
                src="/img/external-link.svg"
                alt=""
            /></span>
          </NuxtLink>
        </div>
        <p class="text-black-50 ps-4">{{ $t('footer.authors.swiss-made') }}</p>
      </div>
    </Transition>
  </p>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';

const creditsVisible = ref(false);
const credits = ref(null);
const button = ref(null);

onClickOutside(credits, () => {
  creditsVisible.value = false;
});

watch(creditsVisible, (newVal) => {
  document.body.style.overflow = newVal ? 'hidden' : '';
});
</script>

<style scoped>
.slide {
  animation: slide 15s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}

@keyframes slide {
  0% {
    transform: translateY(0%);
  }
  12.5% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-20%);
  }
  37.5% {
    transform: translateY(-20%);
  }
  50% {
    transform: translateY(-40%);
  }
  62.5% {
    transform: translateY(-40%);
  }
  75% {
    transform: translateY(-60%);
  }
  87.5% {
    transform: translateY(-60%);
  }
  100% {
    transform: translateY(-80%);
  }
}

.v-enter-active,
.v-leave-active {
  transition: 0.2s ease;
  /* transition-delay: 0.5s; */
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
  translate: 0 20%;
}
</style>
