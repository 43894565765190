<template>
  <button @click="openProfile">
    <ClientOnly>
      <img
        :src="isLoggedIn ? '/img/profiles/face_4.svg' : '/img/profile.svg'"
        class="h-full aspect-square"
        :alt="$t('pages.account')"
      />
      <template #fallback>
        <img src="/img/profile.svg" class="h-full aspect-square" alt="{{ $t('pages.account') }}" />
      </template>
    </ClientOnly>
  </button>
</template>
<script setup lang="ts">
import { useAuthStore } from '@/stores/Auth';
import { storeToRefs } from 'pinia';

const router = useRouter();
const localePath = useLocalePath();

const store = useAuthStore();
const { isLoggedIn } = storeToRefs(store);

const { signInVisible, enableSignUp } = useOverlay();

const openProfile = () => {
  if (isLoggedIn.value) {
    router.push(localePath({ name: `account` }));
  } else {
    signInVisible.value = true;
    enableSignUp.value = true;
  }
};
</script>
