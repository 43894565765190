<template>
  <footer class="py-12 px-4 bg-slate-50 border-t border-black-10 bg-white">
    <div
      class="flex w-4/5 justify-center items-center gap-16 my-48 mx-auto px-24 2xl:px-0 2xl:w-11/12 lg:flex-col lg:my-20 sm:gap-8 sm:mb-16"
    >
      <div class="w-1/4 border p-2 rounded-m border-black-10 lg:w-full">
        <p class="mt-4 mx-4">{{ $t('footer.assistance.title') }}</p>
        <p class="text-black-50 mx-4 mb-3">
          {{ $t('footer.assistance.description') }}
        </p>
        <a
          :href="'tel:' + $t('footer.assistance.phone')"
          class="block text-center py-4 bg-black bg-opacity-[.04] rounded-s text-sm hover:bg-opacity-[.08] transition-colors"
          >{{ $t('footer.assistance.phone') }}</a
        >
      </div>
      <div class="flex w-3/4 gap-4 lg:w-full sm:flex-col sm:gap-8">
        <div class="text-sm basis-0 grow">
          <p class="text-black-50">{{ $t('footer.get-to-know') }}</p>
          <ul>
            <li class="my-2">
              <NuxtLink
                :to="localePath({ name: 'how' })"
                class="text-black hover:text-opacity-60 transition-colors"
                >{{ $t('pages.how') }}</NuxtLink
              >
            </li>
            <li class="my-2">
              <NuxtLink
                :to="localePath({ name: 'about' })"
                class="text-black hover:text-opacity-60 transition-colors"
                >{{ $t('pages.about') }}</NuxtLink
              >
            </li>
            <li class="my-2">
              <NuxtLink
                :to="localePath({ name: 'cars' })"
                class="text-black hover:text-opacity-60 transition-colors"
                >{{ $t('pages.cars') }}</NuxtLink
              >
            </li>
            <li class="my-2">
              <NuxtLink
                :to="localePath({ name: 'blog' })"
                class="text-black hover:text-opacity-60 transition-colors"
                >{{ $t('pages.blog') }}</NuxtLink
              >
            </li>
          </ul>
        </div>
        <div class="text-sm basis-0 grow">
          <p class="text-black-50">{{ $t('footer.help') }}</p>
          <ul>
            <li class="my-2">
              <NuxtLink
                :to="localePath({ name: 'contact' })"
                class="text-black hover:text-opacity-60 transition-colors"
                >{{ $t('pages.contact') }}</NuxtLink
              >
            </li>
            <li class="my-2">
              <NuxtLink
                :to="localePath({ name: 'faq' })"
                class="text-black hover:text-opacity-60 transition-colors"
                >{{ $t('pages.faq') }}</NuxtLink
              >
            </li>
            <li class="my-2">
              <NuxtLink
                :to="localePath({ name: 'terms' })"
                class="text-black hover:text-opacity-60 transition-colors"
                >{{ $t('pages.terms') }}</NuxtLink
              >
            </li>
          </ul>
        </div>
        <div class="text-sm basis-0 grow">
          <p class="text-black-50">{{ $t('footer.address') }}</p>
          <p class="my-2 whitespace-pre-line">
            {{ $t('footer.address-block') }}
          </p>
          <NuxtLink
            :href="$t('contact.maps-url')"
            target="_blank"
            class="text-purple hover:text-opacity-60 transition-colors"
            >{{ $t('footer.map') }}</NuxtLink
          >
        </div>
      </div>
    </div>

    <div
      class="flex justify-between items-center gap-x-8 gap-y-4 w-11/12 mx-auto md:flex-wrap sm:gap-x-2"
    >
      <p class="font-wide text-xl sm:order-1 sm:me-auto sm:grow sm:w-1/2">
        {{ $t('brand.thg-short') }}
      </p>
      <p class="mr-auto text-black-50 sm:order-3 sm:grow sm:w-1/3">
        {{ $t('footer.copyright') }} © {{ new Date().getFullYear() }}
      </p>
      <AppAuthors></AppAuthors>
      <ClientOnly>
        <select v-model="lang" class="outline-none">
          <option value="de">DE</option>
          <option value="en">EN</option>
          <option value="fr">FR</option>
          <option value="it">IT</option>
        </select>
      </ClientOnly>
    </div>
  </footer>
</template>

<script setup lang="ts">
const { locale } = useI18n();
const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();
const lang = ref(locale.value || 'de');
watch(lang, (newVal) => {
  navigateTo(switchLocalePath(newVal));
});
</script>
